import * as React from 'react'
import cn from 'classnames'
import { IconProps } from '@phosphor-icons/react'

export type CheckboxIconProps = {
  checked?: boolean
} & IconProps

export const CheckboxIcon = (props: CheckboxIconProps) => {
  const { checked, ...restProps } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="192"
      height="192"
      fill="#000000"
      viewBox="0 0 256 256"
      className={cn(
        checked && 'text-theme-50',
        'w-4 h-4 flex-shrink-0 focus:text-theme-50'
      )}
      {...restProps}
    >
      <rect width="256" height="256" fill="none" />
      {checked && (
        <polyline
          points="172 104 113.3 160 84 132"
          fill="none"
          className="text-theme-50 stroke-current"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="16"
        />
      )}
      <rect
        x="40"
        y="40"
        width="176"
        height="176"
        rx="8"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      />
    </svg>
  )
}
